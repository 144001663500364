import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import styled from 'styled-components';


const Button = styled.a`
    
    font-size: 13px;
    padding: 0 17px;
    color: #333;
    background-color: transparent;
    border: 2px solid #444;
    border-radius: 17px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 2px;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: #444;
        color: #FFF;
        border-color: transparent !important;
        text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
    }
`;

const Chapter = function(props) {
    const data = useStaticQuery(graphql`
    {
        allFile {
          group(field: relativeDirectory) {
            edges {
              node {
                name
                publicURL
              }
            }
          }
        }
      }
      
    `);

    return (
        <section className="chapters grid-center">
            {data.allFile.group.map((item, i) => {
                return (
                    <div className='chapter-card' key={i}>
                        <img src={item.edges[0].node.publicURL} alt={`Chapter ${i + 1}`}/>
                        <div className='chapter-content'>
                            <span>Donwload</span>
                            <h4>{props.termo} {i + 1}</h4>
                        </div>
                        <div className='download-buttons'>
                            <Button href={item.edges[2].node.publicURL} target='_blank' download>EPUB</Button>
                            <Button href={item.edges[1].node.publicURL} target='_blank' download>PDF</Button>
                        </div>
                    </div>
                )
            })}
        </section>
    )
};


export default Chapter;