import React from 'react';
import { Link } from 'gatsby';
import flagBR from '../images/brazil.png';
import flagUS from '../images/united-states.png'


const VersionToogle = function(props){

    return (
        
        <Link to={props.version === 'português'? '/' : '/en-us'} className='flag-link'>
            <img src={props.version === 'português'? flagBR : flagUS} alt={props.version === 'português'? 'Versão em Português' : 'English Version'} className='flag' />
            <p>{props.version === 'português'? 'Versão em Português' : 'English Version'}</p>
        </Link>

    )
}


export default VersionToogle;