import React from 'react';

const Content = (props) => {
    
    return (
        <article className="grid grid-full main-article">
            {React.Children.map(props.children, (el, i)=> {
                return (
                    <p className="grid-center content-paragraph" key={i}>
                        {el}
                    </p>
                )
            })}
        </article>
    )

};

export default Content;