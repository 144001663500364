import React from 'react';
import divisor from '../images/separa.svg'

const HeaderCall = (props) => {

    return (
        <section className="grid-full grid header-call">
            <h1 className="grid-center">
                {props.children}
            </h1>
            <img src={divisor} className="grid-center divisor" alt='divisor'/>
        </section>
    )
};

export default HeaderCall;