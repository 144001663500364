import React from 'react';
import divisor from '../images/separa.svg';

const ChapterCall = function(props) {

    return (

        <section className="grid-full grid chamada-capitulos">
            <img src={divisor} className="grid-center divisor" alt='divisor'/>
            <h1 className='grid-center'>{props.children[0]} <br /> {props.children[1]}</h1>    
        </section>
    )
}


export default ChapterCall;