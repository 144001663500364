import React from 'react';
import aaronLogo from '../images/logo_aaron.png';

const Header = () => {

    return (
        <section className="header grid-full grid" id="top">
            <img src={aaronLogo} alt="Aaron Fisher" className="arronLogo grid-center"/>
        </section>
    )
}


export default Header;