import React from 'react';
import Header from '../components/header';
import HeaderCall from '../components/headerCall';
import Content from '../components/content';
import ChapterCall from '../components/chapterCall';
import Chapters from '../components/chapters';
import Footer from '../components/footer';
import VersionToogle from '../components/versionToogle';
import GoToTop from '../components/goToTop';
import {Helmet} from 'react-helmet';


const EnglishPage = () => {

    return (
        <main>
            <Helmet>
                <title>Aaron Fischer</title>
                <meta name="author" content="Aaron Fischer" />
                <meta name='description' content='Oficial Aaron Fischer Saga Website' />
                <meta http-equiv='content-language' content='en-us' />
                <meta http-equiv='content-type' content='text/html' charset='UTF-8' />
                <meta name='robots' content='follow' />
            </Helmet>
            <GoToTop />
            <VersionToogle version="português" />   
            <Header />
            <HeaderCall>
            An amazing tale, a dystopian display of current and important social
            issues, highlighting the struggle against oppression and the search for freedom, while revealing the
            darkest traits of the human kind.
            </HeaderCall>
            <section className="grid-full grid section-content">
                <Content>
                    {
                        [`Aaron Fischer tells the story of an apparently ordinary young man,
                        who carries a deadly secret. Living in the humble Harpoon Village, Aaron faces a world ruled
                        by the mighty Elementals, people born with formidable powers, using it to impose a life of
                        servitude and submission to the Common people.`, 
                        `However, everything changes when an innocent prank sets
                        in motion a series of events that will take Aaron into a perilous journey through his own mysterious
                        past, towards his birth rightful destiny.`,
                        `In this thrilling epic, we find elements of beloved classics such
                        as Harry Potter and Hunger Games, while we watch the unfolding of a plot in
                        many ways similar to our own lives.`,
                        `Published in a completely innovative way, you can download every chapter of this story free here on the website!`
                        ]
                    }
                </Content>
                <ChapterCall>
                    {['Discover the Aaron Fischer Saga','Every 15 days a new chapter!']}
                </ChapterCall>
                <Chapters termo="Chapter" />
                <Footer text='Copyrights © 2021 by Carlos Sotto Mayor. All rights reserved.'></Footer>
            </section>
        </main>
    )

}


export default EnglishPage;