import React from 'react';
import Header from '../components/header';
import HeaderCall from '../components/headerCall';
import Content from '../components/content';
import ChapterCall from '../components/chapterCall';
import Chapters from '../components/chapters';
import Footer from '../components/footer';
import VersionToogle from '../components/versionToogle';
import GoToTop from '../components/goToTop';
import {Helmet} from 'react-helmet';

const PortuguesePage = () => {

  return (
    <main>
      <Helmet>
        <title>Aaron Fischer</title>
        <meta name="author" content="Aaron Fischer" />
        <meta name='description' content='Site oficial da saga Aaron Fischer' />
        <meta http-equiv='content-language' content='pt-br' />
        <meta http-equiv='content-type' content='text/html' charset='UTF-8' />
        <meta name='robots' content='follow' />
      </Helmet>
      <GoToTop />
      <VersionToogle version="english" />
      <Header />
      <HeaderCall>
        Um conto fantástico, uma distopia repleta de uma crítica social pertinente e atual, 
        marcado pela luta contra a opressão e pela liberdade, revelando os contornos mais 
        obscuros da humanidade.
      </HeaderCall>
      <section className="grid-full grid section-content">
          <Content>
              {
                  [`Aaron Fischer conta a história de um jovem aparentemente comum, 
                  que carrega consigo um imenso segredo. Vivendo na humilde Vila do Arpão, 
                  Aaron encara um mundo comandado pelos poderosos Elementais, 
                  pessoas nascidas com formidáveis poderes, que usam de sua força para 
                  impor ao resto da humanidade uma existência de serventia e submissão.`, 
                  `Mas tudo muda quando uma inocente brincadeira desencadeia uma série 
                  de eventos que vão levar Aaron numa perigosa jornada pelo próprio passado, 
                  rumo à um destino que esteve guardado para si desde antes de seu nascimento.`,
                  `Neste empolgante épico, reencontramos elementos clássicos e queridos de grandes 
                  obras como Harry Potter e Jogos Vorazes, enquanto assistimos ao desenrolar de 
                  uma trama que em muito se assemelha com o nosso próprio cotidiano.`,
                  `Lançado em um formato completamente inovador, 
                  você poderá baixar cada capítulo desta história de forma gratuita.`
                  ]
              }
          </Content>
          <ChapterCall>
              {['Acompanhe a saga de Aaron Fischer,','um capítulo novo a cada 15 dias!']}
          </ChapterCall>
          <Chapters termo="Capítulo" />
          <Footer text='Copyrights © 2021 by Carlos Sotto Mayor. Todos os direitos reservados'></Footer>
        </section>
    </main>
  )

}


export default PortuguesePage;