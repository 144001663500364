import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebookF} from '@fortawesome/free-brands-svg-icons';

const Footer = function(props) {

    return (
        <footer className='grid-full grid'>
            <div className='grid-center footer-content'>
                <p>{props.text}</p>
                <div>
                    <a href='https://www.facebook.com/AaronFischereaprovadoselementos' className='link-footer'>
                        <FontAwesomeIcon icon={faFacebookF} className='footer-icon' />
                    </a>
                    <a href="https://instagram.com/o_aaronfischer" className='link-footer'>
                        <FontAwesomeIcon icon={faInstagram} className='footer-icon' />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;