import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleUp} from '@fortawesome/free-regular-svg-icons';


const GoToTop = () => {

    return (
        <button onClick={() => scrollTo('#top')} className='gototop-button'>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
        </button>
    )
}

export default GoToTop;